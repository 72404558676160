var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "equipment" }, [
    _c(
      "div",
      { staticClass: "e_content" },
      [
        _vm._m(0),
        _c(
          "el-form",
          {
            ref: "ruleForm2",
            staticClass: "demo-ruleForm",
            staticStyle: { width: "600px" },
            attrs: {
              model: _vm.formInline,
              rules: _vm.rules2,
              "label-width": "160px",
            },
          },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: _vm.$t("searchModule.Induced_screen_name"),
                  prop: "screenName",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: 20,
                    disabled: _vm.isEdit,
                    type: "text",
                    "auto-complete": "off",
                  },
                  model: {
                    value: _vm.formInline.screenName,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formInline,
                        "screenName",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formInline.screenName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-row",
              { attrs: { gutter: 20 } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 4 } },
                  [_c("el-form-item", { attrs: { label: "分辨率:" } })],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "屏幕高度", prop: "screenHeight" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: _vm.isEdit },
                          model: {
                            value: _vm.formInline.screenHeight,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "screenHeight", $$v)
                            },
                            expression: "formInline.screenHeight",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: 10 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "屏幕宽度", prop: "screenWidth" } },
                      [
                        _c("el-input", {
                          attrs: { disabled: _vm.isEdit },
                          model: {
                            value: _vm.formInline.screenWidth,
                            callback: function ($$v) {
                              _vm.$set(_vm.formInline, "screenWidth", $$v)
                            },
                            expression: "formInline.screenWidth",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "诱导屏系统编码", prop: "screenCode" } },
              [
                _c("el-input", {
                  attrs: { maxlength: 20, disabled: _vm.isEdit },
                  model: {
                    value: _vm.formInline.screenCode,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formInline,
                        "screenCode",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formInline.screenCode",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "SN", prop: "equipmentModel" } },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: 30,
                    type: "text",
                    "auto-complete": "off",
                  },
                  model: {
                    value: _vm.formInline.sn,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.formInline,
                        "sn",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "formInline.sn",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "诱导屏类型" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { size: "15" },
                    model: {
                      value: _vm.formInline.category,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formInline,
                          "category",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "formInline.category",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "一级", value: "1" } }),
                    _c("el-option", { attrs: { label: "二级", value: "2" } }),
                    _c("el-option", { attrs: { label: "三级", value: "3" } }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "诱导屏位置", prop: "location" } },
              [
                _c("el-input", {
                  attrs: {
                    rows: "4",
                    resize: "none",
                    type: "textarea",
                    placeholder: "请输入信息",
                    maxlength: "100",
                    "show-word-limit": "",
                  },
                  model: {
                    value: _vm.formInline.location,
                    callback: function ($$v) {
                      _vm.$set(_vm.formInline, "location", $$v)
                    },
                    expression: "formInline.location",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitData } },
                  [_vm._v(_vm._s(_vm.$t("button.preservation")))]
                ),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "title" }, [
      _c("div", { staticClass: "title_icon" }),
      _vm._v(" 诱导屏信息 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }